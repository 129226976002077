import { getCrypto } from './getCrypto';

const CRYPTO_DIGEST_ALG = 'SHA-256';

export async function getSha256Digest(values: ArrayBuffer): Promise<ArrayBuffer> {
  return new Promise(resolve => {
    const result = getCrypto().subtle.digest({ name: CRYPTO_DIGEST_ALG }, values);

    if ('oncomplete' in result) {
      result.oncomplete = evt => resolve(evt.target.result);
    } else {
      result.then(resolve);
    }
  });
}
