import { Predicate } from './types';
import { Guard, GuardFn, Static, guard } from './guard';
import { getArray } from './getArray';

/**
 * Match a tuple which has length equal to the argument count, and each entry
 * matches the guard at the corresponding argument offset.
 *
 * ```ts
 * const stringNumber: Guard<[string, number]> = tuple(typeOf('string'), typeOf('number'));
 * ```
 */
export function tuple<TGuardTuple extends GuardFn<any>[]>(...fns: TGuardTuple): Guard<TupleType<TGuardTuple>> {
  const fnArr: readonly Predicate[][] = fns.map(fn => getArray(fn));
  return guard(
    (x: any): x is TupleType<TGuardTuple> =>
      x instanceof Array && fnArr.every((keyFns, key) => keyFns.some(fn => fn(x[key])))
  );
}

type TupleType<TGuardFns> = {
  [P in keyof TGuardFns]: Static<TGuardFns[P]>;
};
