import { IJwtToken } from './types';

/**
 * Parse a JWT token and return the decoded value, or undefined if the string isn't a value JWT.
 */
export function getDecodedJwtToken(tokenString: string | undefined): IJwtToken | undefined {
  if (tokenString == null) return;

  const [headerEncoded, payloadEncoded, signature] = tokenString.split('.');

  if (signature == null) return;

  try {
    const header = JSON.parse(_decodeBase64(headerEncoded));
    const payload = JSON.parse(_decodeBase64(payloadEncoded));

    return { header, payload, signature };
  } catch {
    return;
  }
}

function _decodeBase64(input: string): string {
  return decodeURIComponent(
    atob(input.replace(/-/g, '+').replace(/_/g, '/'))
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}
